import { makeAutoObservable } from 'mobx';
import type { RootStore } from './root-store';
// eslint-disable-next-line import/no-cycle
import { AffiliateStore } from './affiliate-store';

export class FileStore {
    rootStore: RootStore;

    affiliateStore: AffiliateStore;

    opened = false;

    loading = false;

    agreedTerms = false;

    constructor(affiliateStore: AffiliateStore) {
        this.rootStore = affiliateStore.rootStore;
        this.affiliateStore = affiliateStore;

        makeAutoObservable(this, {
            rootStore: false,
            affiliateStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async exportFile(fileId: string): Promise<void> {
        try {
            this.startLoading();
            const file = await this.rootStore.apiClient.fileController.fileControllerGet({
                id: fileId,
            });

            if (file && file.data && file.data.type === 'Buffer') {
                const arrayBuffer = new Uint8Array(file.data.data);
                const blob = new Blob([arrayBuffer]);
                const url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = file.name;
                link.click();
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
