/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AffiliatePayoutDto,
    AffiliatePayoutDtoFromJSON,
    AffiliatePayoutDtoToJSON,
    AffiliatePayoutPaginatedDto,
    AffiliatePayoutPaginatedDtoFromJSON,
    AffiliatePayoutPaginatedDtoToJSON,
} from '../models';

export interface AffiliatePayoutControllerCreateRequest {
    amount: number;
    affiliateId: string;
}

export interface AffiliatePayoutControllerDeleteRequest {
    id: string;
}

export interface AffiliatePayoutControllerGetRequest {
    affiliateId: string;
    limit?: number;
    offset?: number;
}

/**
 * 
 */
export class AffiliatePayoutApi extends runtime.BaseAPI {

    /**
     * Create affiliate payout
     */
    async affiliatePayoutControllerCreateRaw(requestParameters: AffiliatePayoutControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AffiliatePayoutDto>> {
        if (requestParameters.amount === null || requestParameters.amount === undefined) {
            throw new runtime.RequiredError('amount','Required parameter requestParameters.amount was null or undefined when calling affiliatePayoutControllerCreate.');
        }

        if (requestParameters.affiliateId === null || requestParameters.affiliateId === undefined) {
            throw new runtime.RequiredError('affiliateId','Required parameter requestParameters.affiliateId was null or undefined when calling affiliatePayoutControllerCreate.');
        }

        const queryParameters: any = {};

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        if (requestParameters.affiliateId !== undefined) {
            queryParameters['affiliateId'] = requestParameters.affiliateId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/affiliate-payout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AffiliatePayoutDtoFromJSON(jsonValue));
    }

    /**
     * Create affiliate payout
     */
    async affiliatePayoutControllerCreate(requestParameters: AffiliatePayoutControllerCreateRequest, initOverrides?: RequestInit): Promise<AffiliatePayoutDto> {
        const response = await this.affiliatePayoutControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove affiliate payout
     */
    async affiliatePayoutControllerDeleteRaw(requestParameters: AffiliatePayoutControllerDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling affiliatePayoutControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/affiliate-payout/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Remove affiliate payout
     */
    async affiliatePayoutControllerDelete(requestParameters: AffiliatePayoutControllerDeleteRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.affiliatePayoutControllerDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get by affiliateId
     */
    async affiliatePayoutControllerGetRaw(requestParameters: AffiliatePayoutControllerGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AffiliatePayoutPaginatedDto>> {
        if (requestParameters.affiliateId === null || requestParameters.affiliateId === undefined) {
            throw new runtime.RequiredError('affiliateId','Required parameter requestParameters.affiliateId was null or undefined when calling affiliatePayoutControllerGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/affiliate-payout/list/{affiliateId}`.replace(`{${"affiliateId"}}`, encodeURIComponent(String(requestParameters.affiliateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AffiliatePayoutPaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Get by affiliateId
     */
    async affiliatePayoutControllerGet(requestParameters: AffiliatePayoutControllerGetRequest, initOverrides?: RequestInit): Promise<AffiliatePayoutPaginatedDto> {
        const response = await this.affiliatePayoutControllerGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
