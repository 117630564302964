/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EquityDto,
    EquityDtoFromJSON,
    EquityDtoFromJSONTyped,
    EquityDtoToJSON,
} from './EquityDto';
import {
    SymbolWithExchangeDto,
    SymbolWithExchangeDtoFromJSON,
    SymbolWithExchangeDtoFromJSONTyped,
    SymbolWithExchangeDtoToJSON,
} from './SymbolWithExchangeDto';

/**
 * 
 * @export
 * @interface SymbolFundamentalDataGeneralDto
 */
export interface SymbolFundamentalDataGeneralDto {
    /**
     * 
     * @type {SymbolWithExchangeDto}
     * @memberof SymbolFundamentalDataGeneralDto
     */
    symbol: SymbolWithExchangeDto;
    /**
     * 
     * @type {string}
     * @memberof SymbolFundamentalDataGeneralDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SymbolFundamentalDataGeneralDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SymbolFundamentalDataGeneralDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SymbolFundamentalDataGeneralDto
     */
    sector?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SymbolFundamentalDataGeneralDto
     */
    industry?: string | null;
    /**
     * 
     * @type {Array<EquityDto>}
     * @memberof SymbolFundamentalDataGeneralDto
     */
    regions?: Array<EquityDto> | null;
    /**
     * 
     * @type {Array<EquityDto>}
     * @memberof SymbolFundamentalDataGeneralDto
     */
    sectors?: Array<EquityDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SymbolFundamentalDataGeneralDto
     */
    isDelisted: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SymbolFundamentalDataGeneralDto
     */
    delistedDate?: Date;
}

export function SymbolFundamentalDataGeneralDtoFromJSON(json: any): SymbolFundamentalDataGeneralDto {
    return SymbolFundamentalDataGeneralDtoFromJSONTyped(json, false);
}

export function SymbolFundamentalDataGeneralDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolFundamentalDataGeneralDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'symbol': SymbolWithExchangeDtoFromJSON(json['symbol']),
        'id': json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sector': !exists(json, 'sector') ? undefined : json['sector'],
        'industry': !exists(json, 'industry') ? undefined : json['industry'],
        'regions': !exists(json, 'regions') ? undefined : (json['regions'] === null ? null : (json['regions'] as Array<any>).map(EquityDtoFromJSON)),
        'sectors': !exists(json, 'sectors') ? undefined : (json['sectors'] === null ? null : (json['sectors'] as Array<any>).map(EquityDtoFromJSON)),
        'isDelisted': json['isDelisted'],
        'delistedDate': !exists(json, 'delistedDate') ? undefined : (new Date(json['delistedDate'])),
    };
}

export function SymbolFundamentalDataGeneralDtoToJSON(value?: SymbolFundamentalDataGeneralDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'symbol': SymbolWithExchangeDtoToJSON(value.symbol),
        'id': value.id,
        'type': value.type,
        'description': value.description,
        'sector': value.sector,
        'industry': value.industry,
        'regions': value.regions === undefined ? undefined : (value.regions === null ? null : (value.regions as Array<any>).map(EquityDtoToJSON)),
        'sectors': value.sectors === undefined ? undefined : (value.sectors === null ? null : (value.sectors as Array<any>).map(EquityDtoToJSON)),
        'isDelisted': value.isDelisted,
        'delistedDate': value.delistedDate === undefined ? undefined : (value.delistedDate.toISOString()),
    };
}

