import { makeAutoObservable } from 'mobx';
import { AffiliatePayoutPaginatedDto } from '../../../../defs/api';
import { AffiliatePayoutMobxDto } from './affiliate-payout-mobx-dto';

export class AffiliatePayoutPaginatedMobxDto implements AffiliatePayoutPaginatedDto {
    items: AffiliatePayoutMobxDto[];

    limit?: number;

    offset?: number;

    total: number;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: AffiliatePayoutPaginatedDto): AffiliatePayoutPaginatedMobxDto {
        const mobxDto = new AffiliatePayoutPaginatedMobxDto();
        return Object.assign(mobxDto, dto, { items: AffiliatePayoutMobxDto.createFromArray(dto.items) });
    }

    static createFromArray(dtos: AffiliatePayoutPaginatedDto[]): AffiliatePayoutPaginatedMobxDto[] {
        return dtos.map((dto) => AffiliatePayoutPaginatedMobxDto.create(dto));
    }
}
