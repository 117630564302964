/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AffiliateDto,
    AffiliateDtoFromJSON,
    AffiliateDtoToJSON,
    AffiliateOverviewDto,
    AffiliateOverviewDtoFromJSON,
    AffiliateOverviewDtoToJSON,
    AffiliateStatisticDto,
    AffiliateStatisticDtoFromJSON,
    AffiliateStatisticDtoToJSON,
    CreateAffiliateDto,
    CreateAffiliateDtoFromJSON,
    CreateAffiliateDtoToJSON,
    UpdateAffiliateDto,
    UpdateAffiliateDtoFromJSON,
    UpdateAffiliateDtoToJSON,
} from '../models';

export interface AffiliateControllerCreateRequest {
    createAffiliateDto: CreateAffiliateDto;
}

export interface AffiliateControllerDeleteRequest {
    id: string;
}

export interface AffiliateControllerGetOverviewRequest {
    affiliateId: string;
}

export interface AffiliateControllerGetStatisticsRequest {
    affiliateId: string;
    dateFrom?: string;
    dateTo?: string;
}

export interface AffiliateControllerUpdateRequest {
    id: string;
    updateAffiliateDto: UpdateAffiliateDto;
}

/**
 * 
 */
export class AffiliateApi extends runtime.BaseAPI {

    /**
     * Create affiliate
     */
    async affiliateControllerCreateRaw(requestParameters: AffiliateControllerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AffiliateDto>> {
        if (requestParameters.createAffiliateDto === null || requestParameters.createAffiliateDto === undefined) {
            throw new runtime.RequiredError('createAffiliateDto','Required parameter requestParameters.createAffiliateDto was null or undefined when calling affiliateControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/affiliate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAffiliateDtoToJSON(requestParameters.createAffiliateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AffiliateDtoFromJSON(jsonValue));
    }

    /**
     * Create affiliate
     */
    async affiliateControllerCreate(requestParameters: AffiliateControllerCreateRequest, initOverrides?: RequestInit): Promise<AffiliateDto> {
        const response = await this.affiliateControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove affiliate
     */
    async affiliateControllerDeleteRaw(requestParameters: AffiliateControllerDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling affiliateControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/affiliate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Remove affiliate
     */
    async affiliateControllerDelete(requestParameters: AffiliateControllerDeleteRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.affiliateControllerDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get by userId
     */
    async affiliateControllerGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AffiliateDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/affiliate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AffiliateDtoFromJSON));
    }

    /**
     * Get by userId
     */
    async affiliateControllerGet(initOverrides?: RequestInit): Promise<Array<AffiliateDto>> {
        const response = await this.affiliateControllerGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get overview by affiliateId
     */
    async affiliateControllerGetOverviewRaw(requestParameters: AffiliateControllerGetOverviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AffiliateOverviewDto>> {
        if (requestParameters.affiliateId === null || requestParameters.affiliateId === undefined) {
            throw new runtime.RequiredError('affiliateId','Required parameter requestParameters.affiliateId was null or undefined when calling affiliateControllerGetOverview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/affiliate/overview/{affiliateId}`.replace(`{${"affiliateId"}}`, encodeURIComponent(String(requestParameters.affiliateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AffiliateOverviewDtoFromJSON(jsonValue));
    }

    /**
     * Get overview by affiliateId
     */
    async affiliateControllerGetOverview(requestParameters: AffiliateControllerGetOverviewRequest, initOverrides?: RequestInit): Promise<AffiliateOverviewDto> {
        const response = await this.affiliateControllerGetOverviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get statistics by affiliateId
     */
    async affiliateControllerGetStatisticsRaw(requestParameters: AffiliateControllerGetStatisticsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AffiliateStatisticDto>> {
        if (requestParameters.affiliateId === null || requestParameters.affiliateId === undefined) {
            throw new runtime.RequiredError('affiliateId','Required parameter requestParameters.affiliateId was null or undefined when calling affiliateControllerGetStatistics.');
        }

        const queryParameters: any = {};

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/affiliate/statistics/{affiliateId}`.replace(`{${"affiliateId"}}`, encodeURIComponent(String(requestParameters.affiliateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AffiliateStatisticDtoFromJSON(jsonValue));
    }

    /**
     * Get statistics by affiliateId
     */
    async affiliateControllerGetStatistics(requestParameters: AffiliateControllerGetStatisticsRequest, initOverrides?: RequestInit): Promise<AffiliateStatisticDto> {
        const response = await this.affiliateControllerGetStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update affiliate
     */
    async affiliateControllerUpdateRaw(requestParameters: AffiliateControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AffiliateDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling affiliateControllerUpdate.');
        }

        if (requestParameters.updateAffiliateDto === null || requestParameters.updateAffiliateDto === undefined) {
            throw new runtime.RequiredError('updateAffiliateDto','Required parameter requestParameters.updateAffiliateDto was null or undefined when calling affiliateControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/affiliate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAffiliateDtoToJSON(requestParameters.updateAffiliateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AffiliateDtoFromJSON(jsonValue));
    }

    /**
     * Update affiliate
     */
    async affiliateControllerUpdate(requestParameters: AffiliateControllerUpdateRequest, initOverrides?: RequestInit): Promise<AffiliateDto> {
        const response = await this.affiliateControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
