/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AffiliateSubscriptionTypeDataDto,
    AffiliateSubscriptionTypeDataDtoFromJSON,
    AffiliateSubscriptionTypeDataDtoFromJSONTyped,
    AffiliateSubscriptionTypeDataDtoToJSON,
} from './AffiliateSubscriptionTypeDataDto';

/**
 * 
 * @export
 * @interface AffiliateOverviewDto
 */
export interface AffiliateOverviewDto {
    /**
     * 
     * @type {string}
     * @memberof AffiliateOverviewDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AffiliateOverviewDto
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof AffiliateOverviewDto
     */
    rate: number;
    /**
     * 
     * @type {string}
     * @memberof AffiliateOverviewDto
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof AffiliateOverviewDto
     */
    lastUpdated: Date;
    /**
     * 
     * @type {number}
     * @memberof AffiliateOverviewDto
     */
    currentBalance: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliateOverviewDto
     */
    totalPaidOut: number;
    /**
     * 
     * @type {AffiliateSubscriptionTypeDataDto}
     * @memberof AffiliateOverviewDto
     */
    subscriptionTypeData: AffiliateSubscriptionTypeDataDto;
}

export function AffiliateOverviewDtoFromJSON(json: any): AffiliateOverviewDto {
    return AffiliateOverviewDtoFromJSONTyped(json, false);
}

export function AffiliateOverviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AffiliateOverviewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'rate': json['rate'],
        'userId': json['userId'],
        'lastUpdated': (new Date(json['lastUpdated'])),
        'currentBalance': json['currentBalance'],
        'totalPaidOut': json['totalPaidOut'],
        'subscriptionTypeData': AffiliateSubscriptionTypeDataDtoFromJSON(json['subscriptionTypeData']),
    };
}

export function AffiliateOverviewDtoToJSON(value?: AffiliateOverviewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'rate': value.rate,
        'userId': value.userId,
        'lastUpdated': (value.lastUpdated.toISOString()),
        'currentBalance': value.currentBalance,
        'totalPaidOut': value.totalPaidOut,
        'subscriptionTypeData': AffiliateSubscriptionTypeDataDtoToJSON(value.subscriptionTypeData),
    };
}

