import { makeAutoObservable, runInAction } from 'mobx';
import { SymbolCategoryEnum } from '@app/common';
import { isValid } from 'date-fns';
import type { RootStore } from './root-store';
import { TradeDialogStore } from './trade-dialog-store';
import { TradeImportDialogStore } from './trade-import-dialog-store';
import { isEnumValue } from '../../utils/typeguards/is-enum-value';
import { TradeExportStore } from './trade-export-store';
import { OrderEnum, TradeControllerListTradesRequest, TradeTypeEnum } from '../../defs/api';
import { TradeListMobxDto } from '../mobx/dtos/trade/trade-list-mobx-dto';
import { TradeOverviewMobxDto } from '../mobx/dtos/trade/trade-overview-mobx-dto';

export type SortModel = {
    type?: OrderEnum;
    symbolName?: OrderEnum;
    date?: OrderEnum;
    exchangeName?: OrderEnum;
    unitPrice?: OrderEnum;
    value?: OrderEnum;
    fees?: OrderEnum;
};

export type TradeStoreHydration = {
    trades: TradeListMobxDto[];
    totalTradesCount: number;
    sortModel: SortModel;
};

const DEFAULT_SORT_MODEL: SortModel = {
    date: OrderEnum.Desc,
};

export class TradeStore {
    rootStore: RootStore;

    tradeDialogStore: TradeDialogStore;

    tradeImportDialogStore: TradeImportDialogStore;

    tradeExportStore: TradeExportStore;

    trades: TradeListMobxDto[] = [];

    overview: TradeOverviewMobxDto | null = null;

    totalTradesCount = 0;

    page = 1;

    limit = Number(process.env.NEXT_PUBLIC_PAGE_LIMIT);

    selectedType = '';

    selectedSymbolTypes: string[] = [];

    selectedDateFrom = '';

    selectedDateTo = '';

    filterPhrase = '';

    sortModel: SortModel = {};

    loading = true;

    symbolId: string | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.tradeDialogStore = new TradeDialogStore(this);
        this.tradeImportDialogStore = new TradeImportDialogStore(this);
        this.tradeExportStore = new TradeExportStore(this);
        this.sortModel = DEFAULT_SORT_MODEL;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    setDefaultFilters(): void {
        this.sortModel = DEFAULT_SORT_MODEL;
        this.selectedDateTo = '';
        this.selectedDateFrom = '';
        this.selectedType = '';
        this.filterPhrase = '';
        this.selectedSymbolTypes = [];
        this.limit = Number(process.env.NEXT_PUBLIC_PAGE_LIMIT);
        this.page = 1;
        this.symbolId = null;
    }

    async setSymbolIdAndFetch(symbolId: string): Promise<void> {
        this.symbolId = symbolId;
        await this.fetchTradeList();
    }

    async fetchTradeList(): Promise<void> {
        try {
            this.startLoading();
            const query: TradeControllerListTradesRequest = {
                limit: this.limit,
                offset: (this.page - 1) * this.limit,
                dateFrom: this.selectedDateFrom,
                dateTo: this.selectedDateTo,
                phrase: this.filterPhrase,
            };

            if (this.selectedType) {
                query.type = this.selectedType;
            }

            if (this.selectedSymbolTypes.length > 0) {
                query.symbolTypes = this.selectedSymbolTypes;
            }

            if (this.sortModel) {
                query.sort = this.sortModel;
            }

            if (this.symbolId) {
                query.symbolId = this.symbolId;
            }

            const result = await this.rootStore.apiClient.tradeController.tradeControllerListTrades(query);

            runInAction(() => {
                this.trades = TradeListMobxDto.createFromArray(result?.tradeListSortDto.items);
                this.overview = TradeOverviewMobxDto.create(result.overview);
                this.totalTradesCount = result?.tradeListSortDto.total || 0;
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async setPage(page: number): Promise<void> {
        this.page = page;
        await this.fetchTradeList();
    }

    get pagesCount(): number {
        return Math.ceil(this.totalTradesCount / this.limit) || 0;
    }

    setPageLimit(limit: number | string | null): void {
        if (limit && !Number.isNaN(limit)) {
            this.limit = +limit;
            this.setPage(1);
        }
    }

    get hasTrades(): boolean {
        return this.trades.length > 0;
    }

    setFilterPhrase(phrase: string): void {
        this.filterPhrase = phrase;
        this.setPage(1);
    }

    setSelectedSymbolType(symbolType: string[] | null): void {
        if (symbolType) {
            this.selectedSymbolTypes = symbolType.filter((value) => isEnumValue(SymbolCategoryEnum, value));
        } else {
            this.selectedSymbolTypes = [];
        }
        this.setPage(1);
    }

    setSelectedTradeType(tradeType: TradeTypeEnum): void {
        if (this.selectedType === tradeType) {
            this.selectedType = '';
        } else {
            this.selectedType = tradeType;
        }
        this.setPage(1);
    }

    setSelectedDateFrom(dateFrom: string): void {
        this.selectedDateFrom = isValid(new Date(dateFrom)) ? dateFrom : '';
    }

    setSelectedDateTo(dateTo: string): void {
        this.selectedDateTo = isValid(new Date(dateTo)) ? dateTo : '';
    }

    getFieldSortMethod(field: keyof SortModel): OrderEnum | null {
        if (this.sortModel) {
            return this.sortModel[field] || null;
        }
        return null;
    }

    setSortModel(sortModel: { field: keyof SortModel; method: OrderEnum | null }): void {
        this.sortModel = {};
        if (sortModel.method) {
            this.sortModel[sortModel.field] = sortModel.method;
        } else {
            this.sortModel = DEFAULT_SORT_MODEL;
        }

        this.setPage(1);
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    switchTradeSelected(trade: TradeListMobxDto) {
        this.trades.find((t) => t.id === trade.id)?.switchSelected();
    }

    get selectedTradesCount(): number {
        return this.trades.filter((t) => t.isSelected).length;
    }

    setAllTradesSelection(checked: boolean): void {
        this.trades.forEach((trade) => {
            trade.setSelected(checked);
        });
    }

    get isPageSelected(): boolean {
        return this.trades.filter((trade) => !trade.isSelected).length < 1;
    }

    async deleteSelectedTrades(): Promise<void> {
        const selectedTrades = [...this.trades.filter((t) => t.isSelected)];
        await this.deleteTrades(selectedTrades);
    }

    async deleteTrades(trades: TradeListMobxDto[]) {
        this.startLoading();

        await Promise.all(
            trades.map(async (trade) => {
                const tradeId = trade.id;
                try {
                    const res = await this.rootStore.apiClient.tradeController.tradeControllerDeleteTrade({
                        id: tradeId,
                    });
                    if (!res) {
                        throw new Error();
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                    this.rootStore.alertStore.setErrorMessageByStatus(e.message);
                }
                return null;
            }),
        );

        if (trades.length >= this.trades.length && this.page > 1) {
            await this.setPage(this.page - 1);
        } else {
            await this.fetchTradeList();
        }

        runInAction(() => {
            this.rootStore.portfolioStore.fetchPortfolioList();
        });

        this.stopLoading();
    }

    async recalculateUnprocessedTrades(portfolioIds?: string[], showInfoModal = true) {
        const { selectedPortfolio } = this.rootStore.portfolioStore;

        try {
            this.startLoading();
            const res =
                await this.rootStore.apiClient.tradeImportController.tradeImportControllerRecalculateUnprocessedTrades({
                    recalculateUnprocessedTradesDto: {
                        portfolioIds: portfolioIds || [selectedPortfolio.id],
                    },
                });

            if (res) {
                runInAction(() => {
                    selectedPortfolio.setIsRecalculating(true);
                    if (showInfoModal && this.rootStore.userStore.settings?.showPortfolioValueCalculationInfo) {
                        this.rootStore.portfolioValueCalculationInfoDialogStore.open();
                    }
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
