/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AffiliateSubscriptionTypeDataDto
 */
export interface AffiliateSubscriptionTypeDataDto {
    /**
     * 
     * @type {number}
     * @memberof AffiliateSubscriptionTypeDataDto
     */
    monthlyUsersCount: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliateSubscriptionTypeDataDto
     */
    yearlyUsersCount: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliateSubscriptionTypeDataDto
     */
    totalUsersCount: number;
}

export function AffiliateSubscriptionTypeDataDtoFromJSON(json: any): AffiliateSubscriptionTypeDataDto {
    return AffiliateSubscriptionTypeDataDtoFromJSONTyped(json, false);
}

export function AffiliateSubscriptionTypeDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AffiliateSubscriptionTypeDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monthlyUsersCount': json['monthlyUsersCount'],
        'yearlyUsersCount': json['yearlyUsersCount'],
        'totalUsersCount': json['totalUsersCount'],
    };
}

export function AffiliateSubscriptionTypeDataDtoToJSON(value?: AffiliateSubscriptionTypeDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monthlyUsersCount': value.monthlyUsersCount,
        'yearlyUsersCount': value.yearlyUsersCount,
        'totalUsersCount': value.totalUsersCount,
    };
}

