/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AssetTypeEnum {
    Other = 'other',
    Property = 'property',
    SavingsAccount = 'savingsAccount',
    Mortgage = 'mortgage',
    Equipment = 'equipment',
    Business = 'business',
    Cash = 'cash',
    MutualFund = 'mutualFund',
    Art = 'art',
    PensionSavings = 'pensionSavings',
    Bond = 'bond'
}

export function AssetTypeEnumFromJSON(json: any): AssetTypeEnum {
    return AssetTypeEnumFromJSONTyped(json, false);
}

export function AssetTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetTypeEnum {
    return json as AssetTypeEnum;
}

export function AssetTypeEnumToJSON(value?: AssetTypeEnum | null): any {
    return value as any;
}

