/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DayCountConventionEnum {
    _360 = 'ACT/360',
    _365 = 'ACT/365'
}

export function DayCountConventionEnumFromJSON(json: any): DayCountConventionEnum {
    return DayCountConventionEnumFromJSONTyped(json, false);
}

export function DayCountConventionEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DayCountConventionEnum {
    return json as DayCountConventionEnum;
}

export function DayCountConventionEnumToJSON(value?: DayCountConventionEnum | null): any {
    return value as any;
}

