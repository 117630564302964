import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
import { AffiliateMobxDto } from '../mobx/dtos/affiliate/affiliate-mobx-dto';
import { AffiliateOverviewMobxDto } from '../mobx/dtos/affiliate/affiliate-overview-mobx-dto';
// eslint-disable-next-line import/no-cycle
import { AffiliatePayoutStore } from './affiliate-payout-store';
// eslint-disable-next-line import/no-cycle
import { AffiliateStatisticStore } from './affiliate-statistic-store';
// eslint-disable-next-line import/no-cycle
import { AffiliateRegistrationDialogStore } from './affiliate-registration-dialog-store';
// eslint-disable-next-line import/no-cycle
import { AffiliatePaymentRequestDialogStore } from './affiliate-payment-request-dialog-store';
// eslint-disable-next-line import/no-cycle
import { FileStore } from './file-store';

export class AffiliateStore {
    rootStore: RootStore;

    affiliatePayoutStore: AffiliatePayoutStore;

    fileStore: FileStore;

    affiliateStatisticStore: AffiliateStatisticStore;

    affiliateRegistrationDialogStore: AffiliateRegistrationDialogStore;

    affiliatePaymentRequestDialogStore: AffiliatePaymentRequestDialogStore;

    loading = false;

    affiliate: AffiliateMobxDto | null = null;

    affiliateOverview: AffiliateOverviewMobxDto | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.affiliatePayoutStore = new AffiliatePayoutStore(this);
        this.fileStore = new FileStore(this);
        this.affiliateStatisticStore = new AffiliateStatisticStore(this);
        this.affiliateRegistrationDialogStore = new AffiliateRegistrationDialogStore(this);
        this.affiliatePaymentRequestDialogStore = new AffiliatePaymentRequestDialogStore(this);

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async fetchAffiliate(): Promise<void> {
        this.startLoading();
        try {
            const [affiliateDtos] = await Promise.all([
                this.rootStore.apiClient.affiliateController.affiliateControllerGet(),
            ]);

            const affiliateDto = affiliateDtos[0];

            if (affiliateDto) {
                const [affiliateOverviewDto] = await Promise.all([
                    this.rootStore.apiClient.affiliateController.affiliateControllerGetOverview({
                        affiliateId: affiliateDto.id,
                    }),
                ]);

                runInAction(() => {
                    this.affiliate = AffiliateMobxDto.create(affiliateDto);
                    this.affiliateOverview = AffiliateOverviewMobxDto.create(affiliateOverviewDto);
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
