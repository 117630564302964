/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AffiliateStatisticDto
 */
export interface AffiliateStatisticDto {
    /**
     * 
     * @type {number}
     * @memberof AffiliateStatisticDto
     */
    registrationsCount: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliateStatisticDto
     */
    commissions: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliateStatisticDto
     */
    paymentsCount: number;
}

export function AffiliateStatisticDtoFromJSON(json: any): AffiliateStatisticDto {
    return AffiliateStatisticDtoFromJSONTyped(json, false);
}

export function AffiliateStatisticDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AffiliateStatisticDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrationsCount': json['registrationsCount'],
        'commissions': json['commissions'],
        'paymentsCount': json['paymentsCount'],
    };
}

export function AffiliateStatisticDtoToJSON(value?: AffiliateStatisticDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrationsCount': value.registrationsCount,
        'commissions': value.commissions,
        'paymentsCount': value.paymentsCount,
    };
}

