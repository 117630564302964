import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
// eslint-disable-next-line import/no-cycle
import { AffiliateStore } from './affiliate-store';
import { CreateAffiliatePayoutMobxDto } from '../mobx/dtos/affiliate/create-affiliate-payout-mobx-dto';

export class AffiliatePaymentRequestDialogStore {
    rootStore: RootStore;

    affiliateStore: AffiliateStore;

    opened = false;

    loading = false;

    constructor(affiliateStore: AffiliateStore) {
        this.rootStore = affiliateStore.rootStore;
        this.affiliateStore = affiliateStore;

        makeAutoObservable(this, {
            rootStore: false,
            affiliateStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    openDialog(): void {
        this.rootStore.alertStore.setModalPlacement();
        runInAction(() => {
            this.opened = true;
        });
    }

    closeDialog(): void {
        runInAction(() => {
            this.opened = false;
        });
        this.rootStore.alertStore.setDefaultPlacement();
    }

    async createPaymentRequest(input: CreateAffiliatePayoutMobxDto, file: File): Promise<void> {
        try {
            this.startLoading();

            const formData = new FormData();
            formData.append('file', file);

            await this.rootStore.apiClient.affiliatePayoutController.affiliatePayoutControllerCreate(
                {
                    ...input,
                },
                {
                    body: formData,
                },
            );
            await this.affiliateStore.affiliatePayoutStore.fetchAffiliatePayouts(input.affiliateId);
            this.closeDialog();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
