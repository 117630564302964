/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RecalculateUnprocessedTradesDto,
    RecalculateUnprocessedTradesDtoFromJSON,
    RecalculateUnprocessedTradesDtoToJSON,
    TradeImportResponseDto,
    TradeImportResponseDtoFromJSON,
    TradeImportResponseDtoToJSON,
} from '../models';

export interface TradeImportControllerImportRequest {
    importType: string;
    processTrades: boolean;
    fileType?: string;
}

export interface TradeImportControllerRecalculateUnprocessedTradesRequest {
    recalculateUnprocessedTradesDto: RecalculateUnprocessedTradesDto;
}

export interface TradeImportControllerRunTrading212IntegrationRequest {
    id: string;
    processTrades: boolean;
}

/**
 * 
 */
export class TradesImportApi extends runtime.BaseAPI {

    /**
     * Import trade(s) from csv
     */
    async tradeImportControllerImportRaw(requestParameters: TradeImportControllerImportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TradeImportResponseDto>> {
        if (requestParameters.importType === null || requestParameters.importType === undefined) {
            throw new runtime.RequiredError('importType','Required parameter requestParameters.importType was null or undefined when calling tradeImportControllerImport.');
        }

        if (requestParameters.processTrades === null || requestParameters.processTrades === undefined) {
            throw new runtime.RequiredError('processTrades','Required parameter requestParameters.processTrades was null or undefined when calling tradeImportControllerImport.');
        }

        const queryParameters: any = {};

        if (requestParameters.processTrades !== undefined) {
            queryParameters['processTrades'] = requestParameters.processTrades;
        }

        if (requestParameters.fileType !== undefined) {
            queryParameters['fileType'] = requestParameters.fileType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trades/import/{importType}`.replace(`{${"importType"}}`, encodeURIComponent(String(requestParameters.importType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeImportResponseDtoFromJSON(jsonValue));
    }

    /**
     * Import trade(s) from csv
     */
    async tradeImportControllerImport(requestParameters: TradeImportControllerImportRequest, initOverrides?: RequestInit): Promise<TradeImportResponseDto> {
        const response = await this.tradeImportControllerImportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recalculate unprocessed trades
     */
    async tradeImportControllerRecalculateUnprocessedTradesRaw(requestParameters: TradeImportControllerRecalculateUnprocessedTradesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.recalculateUnprocessedTradesDto === null || requestParameters.recalculateUnprocessedTradesDto === undefined) {
            throw new runtime.RequiredError('recalculateUnprocessedTradesDto','Required parameter requestParameters.recalculateUnprocessedTradesDto was null or undefined when calling tradeImportControllerRecalculateUnprocessedTrades.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trades/import/recalculate-unprocessed-trades`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecalculateUnprocessedTradesDtoToJSON(requestParameters.recalculateUnprocessedTradesDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Recalculate unprocessed trades
     */
    async tradeImportControllerRecalculateUnprocessedTrades(requestParameters: TradeImportControllerRecalculateUnprocessedTradesRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.tradeImportControllerRecalculateUnprocessedTradesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run T212 portfolio integration
     */
    async tradeImportControllerRunTrading212IntegrationRaw(requestParameters: TradeImportControllerRunTrading212IntegrationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TradeImportResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tradeImportControllerRunTrading212Integration.');
        }

        if (requestParameters.processTrades === null || requestParameters.processTrades === undefined) {
            throw new runtime.RequiredError('processTrades','Required parameter requestParameters.processTrades was null or undefined when calling tradeImportControllerRunTrading212Integration.');
        }

        const queryParameters: any = {};

        if (requestParameters.processTrades !== undefined) {
            queryParameters['processTrades'] = requestParameters.processTrades;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/trades/import/run-trading212-integration/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TradeImportResponseDtoFromJSON(jsonValue));
    }

    /**
     * Run T212 portfolio integration
     */
    async tradeImportControllerRunTrading212Integration(requestParameters: TradeImportControllerRunTrading212IntegrationRequest, initOverrides?: RequestInit): Promise<TradeImportResponseDto> {
        const response = await this.tradeImportControllerRunTrading212IntegrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
