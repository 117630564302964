/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AffiliatePayoutDto,
    AffiliatePayoutDtoFromJSON,
    AffiliatePayoutDtoFromJSONTyped,
    AffiliatePayoutDtoToJSON,
} from './AffiliatePayoutDto';

/**
 * 
 * @export
 * @interface AffiliatePayoutPaginatedDto
 */
export interface AffiliatePayoutPaginatedDto {
    /**
     * 
     * @type {Array<AffiliatePayoutDto>}
     * @memberof AffiliatePayoutPaginatedDto
     */
    items: Array<AffiliatePayoutDto>;
    /**
     * 
     * @type {number}
     * @memberof AffiliatePayoutPaginatedDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliatePayoutPaginatedDto
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliatePayoutPaginatedDto
     */
    offset?: number;
}

export function AffiliatePayoutPaginatedDtoFromJSON(json: any): AffiliatePayoutPaginatedDto {
    return AffiliatePayoutPaginatedDtoFromJSONTyped(json, false);
}

export function AffiliatePayoutPaginatedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AffiliatePayoutPaginatedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(AffiliatePayoutDtoFromJSON)),
        'total': json['total'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
    };
}

export function AffiliatePayoutPaginatedDtoToJSON(value?: AffiliatePayoutPaginatedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(AffiliatePayoutDtoToJSON)),
        'total': value.total,
        'limit': value.limit,
        'offset': value.offset,
    };
}

