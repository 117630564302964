/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AffiliatePayoutStatusEnum {
    InProgress = 'inProgress',
    Approved = 'approved',
    Rejected = 'rejected',
    Done = 'done'
}

export function AffiliatePayoutStatusEnumFromJSON(json: any): AffiliatePayoutStatusEnum {
    return AffiliatePayoutStatusEnumFromJSONTyped(json, false);
}

export function AffiliatePayoutStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AffiliatePayoutStatusEnum {
    return json as AffiliatePayoutStatusEnum;
}

export function AffiliatePayoutStatusEnumToJSON(value?: AffiliatePayoutStatusEnum | null): any {
    return value as any;
}

