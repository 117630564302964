/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationTypeEnum {
    Manual = 'manual',
    Interest = 'interest',
    ScheduledIncrease = 'scheduledIncrease',
    ScheduledDecrease = 'scheduledDecrease'
}

export function OperationTypeEnumFromJSON(json: any): OperationTypeEnum {
    return OperationTypeEnumFromJSONTyped(json, false);
}

export function OperationTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationTypeEnum {
    return json as OperationTypeEnum;
}

export function OperationTypeEnumToJSON(value?: OperationTypeEnum | null): any {
    return value as any;
}

