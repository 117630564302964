import { makeAutoObservable, runInAction } from 'mobx';
import { ErrorStatusEnum } from '@app/common';
import type { RootStore } from './root-store';
import { ProfileFormValuesMobxDto } from '../mobx/dtos/user/profile-form-values-mobx-dto';
import { LookupKeyEnum } from '../../defs/api';
import { UserSettingsMobxDto } from '../mobx/dtos/user/user-settings-mobx-dto';
import { UpdateUserSettingsMobxDto } from '../mobx/dtos/user/update-user-settings-mobx-dto';

export type UserStoreHydration = {
    newsletter: boolean;
};

export class UserStore {
    rootStore: RootStore;

    newsletter = false;

    loading = false;

    settings: UserSettingsMobxDto | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.init();
        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    hydrate(): UserStoreHydration {
        return {
            newsletter: this.newsletter,
        };
    }

    rehydrate(data: UserStoreHydration): void {
        this.newsletter = data.newsletter;
    }

    init(): void {
        this.newsletter = this.rootStore.authStore.userProfile?.newsletter || false;
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async updateUserProfile(values: ProfileFormValuesMobxDto): Promise<void> {
        this.startLoading();
        try {
            const { nicknameAlreadyTaken } =
                await this.rootStore.apiClient.userController.userControllerIsNicknameAlreadyTaken({
                    nickname: values.nickname,
                });

            if (nicknameAlreadyTaken) {
                throw new Error(ErrorStatusEnum.NicknameAlreadyTaken);
            }

            const res = await this.rootStore.apiClient.userController.userControllerUpdateProfile({
                profileFormValuesDto: values,
            });
            if (!res) {
                throw new Error();
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async hadStornoDiscount(): Promise<boolean | null> {
        this.startLoading();
        try {
            return this.rootStore.apiClient.userController.userControllerHadStornoDiscount();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);

            return null;
        } finally {
            this.stopLoading();
        }
    }

    async fetchUserSettings(): Promise<void> {
        this.startLoading();
        try {
            const settings = await this.rootStore.apiClient.userController.userControllerGetSettings();

            if (settings) {
                runInAction(() => {
                    this.settings = UserSettingsMobxDto.create(settings);
                    this.rootStore.themeStore.init();
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async updateSettings(data: UpdateUserSettingsMobxDto): Promise<void> {
        this.startLoading();
        try {
            const res = await this.rootStore.apiClient.userController.userControllerUpdateSettings({
                updateUserSettingsDto: data,
            });

            if (!res) {
                throw new Error();
            }

            await this.fetchUserSettings();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async updateInterestedInSubscription(lookupKey: LookupKeyEnum | null): Promise<void> {
        this.startLoading();
        try {
            const res = await this.rootStore.apiClient.userController.userControllerUpdateInterestedInSubscription({
                interestedInSubscriptionDto: {
                    interestedInSubscription: lookupKey || undefined,
                },
            });
            if (!res) {
                throw new Error();
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    async persistInterestedInSubscription(): Promise<void> {
        const subscription = this.rootStore.cookieService.getInterestedInSubscription();
        if (subscription) {
            await this.updateInterestedInSubscription(subscription);
            this.rootStore.cookieService.setInterestedInSubscription(null);
        }
    }
}
