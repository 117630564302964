/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BufferDto,
    BufferDtoFromJSON,
    BufferDtoFromJSONTyped,
    BufferDtoToJSON,
} from './BufferDto';

/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {BufferDto}
     * @memberof FileDto
     */
    data: BufferDto;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    mimeType: string;
}

export function FileDtoFromJSON(json: any): FileDto {
    return FileDtoFromJSONTyped(json, false);
}

export function FileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': BufferDtoFromJSON(json['data']),
        'id': json['id'],
        'name': json['name'],
        'mimeType': json['mimeType'],
    };
}

export function FileDtoToJSON(value?: FileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': BufferDtoToJSON(value.data),
        'id': value.id,
        'name': value.name,
        'mimeType': value.mimeType,
    };
}

