export const routes = {
    web: {
        index: '/',
        indexLogout: '/?logout=true',
        termsAndConditions: '/terms-and-conditions',
        featureList: '/#featureList',
        pricing: '/pricing',
        faq: '/faq',
        exportGuide: '/export-guide',
        waitingList: '/waiting-list',
        maintenance: '/maintenance',
        contact: '/contact',
        marketingCommunicationTerms: '/marketing-communication-terms',
        privacyPolicy: '/privacy-policy',
        affiliate: '/affiliate',
    },
    app: {
        index: '/app',
        pricing: '/app/pricing',
        affiliate: '/app/affiliate',
        sharedPortfolio: '/app/portfolio',
        invested: '/app/invested',
        profits: '/app/profits',
        dividends: '/app/dividends',
        taxReport: '/app/tax-report',
        profile: '/app/profile',
        portfolios: '/app/portfolios',
        symbols: '/app/symbols',
        trades: '/app/trades',
        users: '/app/users',
        watchlist: '/app/watchlist',
        portfolioList: '/app/portfolio-list',
        faq: '/app/faq',
        admin: {
            dashboard: '/app/admin/dashboard',
            statistics: '/app/admin/statistics',
            users: '/app/admin/users',
            portfolios: '/app/admin/portfolios',
            alternativeSymbols: '/app/admin/alternative-symbols',
            symbols: '/app/admin/symbols',
        },
        // exportGuide: {
        //     degiro: '/app/export-guide/degiro',
        //     etoro: '/app/export-guide/etoro',
        //     interactivebrokers: '/app/export-guide/ib',
        //     trading212: '/app/export-guide/trading212',
        //     xtb: '/app/export-guide/xtb',
        // },
    },
};
