import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
// eslint-disable-next-line import/no-cycle
import { AffiliateStore } from './affiliate-store';
import { CreateAffiliateDto } from '../../defs/api';

export class AffiliateRegistrationDialogStore {
    rootStore: RootStore;

    affiliateStore: AffiliateStore;

    opened = false;

    loading = false;

    agreedTerms = false;

    constructor(affiliateStore: AffiliateStore) {
        this.rootStore = affiliateStore.rootStore;
        this.affiliateStore = affiliateStore;

        makeAutoObservable(this, {
            rootStore: false,
            affiliateStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async register(data: CreateAffiliateDto): Promise<void> {
        try {
            this.startLoading();
            await this.rootStore.apiClient.affiliateController.affiliateControllerCreate({
                createAffiliateDto: data,
            });
            await this.affiliateStore.fetchAffiliate();
            this.closeDialog();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }

    openDialog(): void {
        this.rootStore.alertStore.setModalPlacement();
        runInAction(() => {
            this.opened = true;
            this.agreedTerms = false;
        });
    }

    switchAgreedTerms(): void {
        runInAction(() => {
            this.agreedTerms = !this.agreedTerms;
        });
    }

    closeDialog(): void {
        runInAction(() => {
            this.opened = false;
        });
        this.rootStore.alertStore.setDefaultPlacement();
    }
}
