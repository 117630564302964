/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FileDto,
    FileDtoFromJSON,
    FileDtoToJSON,
} from '../models';

export interface FileControllerDownloadFileRequest {
    id: string;
}

export interface FileControllerGetRequest {
    id: string;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     */
    async fileControllerDownloadFileRaw(requestParameters: FileControllerDownloadFileRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fileControllerDownloadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/file/{id}/download`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fileControllerDownloadFile(requestParameters: FileControllerDownloadFileRequest, initOverrides?: RequestInit): Promise<void> {
        await this.fileControllerDownloadFileRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fileControllerGetRaw(requestParameters: FileControllerGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FileDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fileControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/file/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDtoFromJSON(jsonValue));
    }

    /**
     */
    async fileControllerGet(requestParameters: FileControllerGetRequest, initOverrides?: RequestInit): Promise<FileDto> {
        const response = await this.fileControllerGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
