import { makeAutoObservable } from 'mobx';
import { AffiliateDto } from '../../../../defs/api';

export class AffiliateMobxDto implements AffiliateDto {
    code: string = '';

    id: string = '';

    lastUpdated: Date = new Date();

    rate: number = 0;

    userId: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: AffiliateDto): AffiliateMobxDto {
        const mobxDto = new AffiliateMobxDto();
        return Object.assign(mobxDto, dto, {});
    }

    static createFromArray(dtos: AffiliateDto[]): AffiliateMobxDto[] {
        return dtos.map((dto) => AffiliateMobxDto.create(dto));
    }
}
