/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ScheduledFrequencyEnum {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    SemiAnnually = 'semiAnnually',
    Yearly = 'yearly'
}

export function ScheduledFrequencyEnumFromJSON(json: any): ScheduledFrequencyEnum {
    return ScheduledFrequencyEnumFromJSONTyped(json, false);
}

export function ScheduledFrequencyEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduledFrequencyEnum {
    return json as ScheduledFrequencyEnum;
}

export function ScheduledFrequencyEnumToJSON(value?: ScheduledFrequencyEnum | null): any {
    return value as any;
}

