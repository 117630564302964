/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LayoutEnum,
    LayoutEnumFromJSON,
    LayoutEnumFromJSONTyped,
    LayoutEnumToJSON,
} from './LayoutEnum';
import {
    MailOverviewPeriodEnum,
    MailOverviewPeriodEnumFromJSON,
    MailOverviewPeriodEnumFromJSONTyped,
    MailOverviewPeriodEnumToJSON,
} from './MailOverviewPeriodEnum';
import {
    ThemeEnum,
    ThemeEnumFromJSON,
    ThemeEnumFromJSONTyped,
    ThemeEnumToJSON,
} from './ThemeEnum';
import {
    UserEntity,
    UserEntityFromJSON,
    UserEntityFromJSONTyped,
    UserEntityToJSON,
} from './UserEntity';

/**
 * 
 * @export
 * @interface UserSettingsEntity
 */
export interface UserSettingsEntity {
    /**
     * 
     * @type {UserEntity}
     * @memberof UserSettingsEntity
     */
    user: UserEntity;
    /**
     * 
     * @type {ThemeEnum}
     * @memberof UserSettingsEntity
     */
    theme: ThemeEnum;
    /**
     * 
     * @type {LayoutEnum}
     * @memberof UserSettingsEntity
     */
    layout: LayoutEnum;
    /**
     * 
     * @type {MailOverviewPeriodEnum}
     * @memberof UserSettingsEntity
     */
    mailOverviewPeriod: MailOverviewPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsEntity
     */
    userId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsEntity
     */
    showSymbolAmountWarning: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsEntity
     */
    showPortfolioValueCalculationInfo: boolean;
}

export function UserSettingsEntityFromJSON(json: any): UserSettingsEntity {
    return UserSettingsEntityFromJSONTyped(json, false);
}

export function UserSettingsEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettingsEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserEntityFromJSON(json['user']),
        'theme': ThemeEnumFromJSON(json['theme']),
        'layout': LayoutEnumFromJSON(json['layout']),
        'mailOverviewPeriod': MailOverviewPeriodEnumFromJSON(json['mailOverviewPeriod']),
        'id': json['id'],
        'userId': json['userId'],
        'showSymbolAmountWarning': json['showSymbolAmountWarning'],
        'showPortfolioValueCalculationInfo': json['showPortfolioValueCalculationInfo'],
    };
}

export function UserSettingsEntityToJSON(value?: UserSettingsEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserEntityToJSON(value.user),
        'theme': ThemeEnumToJSON(value.theme),
        'layout': LayoutEnumToJSON(value.layout),
        'mailOverviewPeriod': MailOverviewPeriodEnumToJSON(value.mailOverviewPeriod),
        'id': value.id,
        'userId': value.userId,
        'showSymbolAmountWarning': value.showSymbolAmountWarning,
        'showPortfolioValueCalculationInfo': value.showPortfolioValueCalculationInfo,
    };
}

