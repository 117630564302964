/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LookupKeyEnum,
    LookupKeyEnumFromJSON,
    LookupKeyEnumFromJSONTyped,
    LookupKeyEnumToJSON,
} from './LookupKeyEnum';
import {
    SubscriptionEnum,
    SubscriptionEnumFromJSON,
    SubscriptionEnumFromJSONTyped,
    SubscriptionEnumToJSON,
} from './SubscriptionEnum';
import {
    UserRoleEnum,
    UserRoleEnumFromJSON,
    UserRoleEnumFromJSONTyped,
    UserRoleEnumToJSON,
} from './UserRoleEnum';

/**
 * 
 * @export
 * @interface UserProfileDto
 */
export interface UserProfileDto {
    /**
     * 
     * @type {UserRoleEnum}
     * @memberof UserProfileDto
     */
    role: UserRoleEnum;
    /**
     * 
     * @type {SubscriptionEnum}
     * @memberof UserProfileDto
     */
    subscription: SubscriptionEnum;
    /**
     * 
     * @type {LookupKeyEnum}
     * @memberof UserProfileDto
     */
    interestedInSubscription: LookupKeyEnum;
    /**
     * 
     * @type {LookupKeyEnum}
     * @memberof UserProfileDto
     */
    lookupKey?: LookupKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    zendeskToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    isOnWaitingList: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    nickname: string;
    /**
     * 
     * @type {Date}
     * @memberof UserProfileDto
     */
    subscriptionEndDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    newsletter: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    agreedTerms: boolean;
}

export function UserProfileDtoFromJSON(json: any): UserProfileDto {
    return UserProfileDtoFromJSONTyped(json, false);
}

export function UserProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': UserRoleEnumFromJSON(json['role']),
        'subscription': SubscriptionEnumFromJSON(json['subscription']),
        'interestedInSubscription': LookupKeyEnumFromJSON(json['interestedInSubscription']),
        'lookupKey': !exists(json, 'lookupKey') ? undefined : LookupKeyEnumFromJSON(json['lookupKey']),
        'zendeskToken': !exists(json, 'zendeskToken') ? undefined : json['zendeskToken'],
        'id': json['id'],
        'email': json['email'],
        'isOnWaitingList': json['isOnWaitingList'],
        'fullName': json['fullName'],
        'nickname': json['nickname'],
        'subscriptionEndDate': (json['subscriptionEndDate'] === null ? null : new Date(json['subscriptionEndDate'])),
        'newsletter': json['newsletter'],
        'agreedTerms': json['agreedTerms'],
    };
}

export function UserProfileDtoToJSON(value?: UserProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': UserRoleEnumToJSON(value.role),
        'subscription': SubscriptionEnumToJSON(value.subscription),
        'interestedInSubscription': LookupKeyEnumToJSON(value.interestedInSubscription),
        'lookupKey': LookupKeyEnumToJSON(value.lookupKey),
        'zendeskToken': value.zendeskToken,
        'id': value.id,
        'email': value.email,
        'isOnWaitingList': value.isOnWaitingList,
        'fullName': value.fullName,
        'nickname': value.nickname,
        'subscriptionEndDate': (value.subscriptionEndDate === null ? null : value.subscriptionEndDate.toISOString()),
        'newsletter': value.newsletter,
        'agreedTerms': value.agreedTerms,
    };
}

