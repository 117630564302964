/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LayoutEnum,
    LayoutEnumFromJSON,
    LayoutEnumFromJSONTyped,
    LayoutEnumToJSON,
} from './LayoutEnum';
import {
    MailOverviewPeriodEnum,
    MailOverviewPeriodEnumFromJSON,
    MailOverviewPeriodEnumFromJSONTyped,
    MailOverviewPeriodEnumToJSON,
} from './MailOverviewPeriodEnum';
import {
    ThemeEnum,
    ThemeEnumFromJSON,
    ThemeEnumFromJSONTyped,
    ThemeEnumToJSON,
} from './ThemeEnum';

/**
 * 
 * @export
 * @interface UpdateUserSettingsDto
 */
export interface UpdateUserSettingsDto {
    /**
     * 
     * @type {ThemeEnum}
     * @memberof UpdateUserSettingsDto
     */
    theme?: ThemeEnum;
    /**
     * 
     * @type {LayoutEnum}
     * @memberof UpdateUserSettingsDto
     */
    layout?: LayoutEnum;
    /**
     * 
     * @type {MailOverviewPeriodEnum}
     * @memberof UpdateUserSettingsDto
     */
    mailOverviewPeriod?: MailOverviewPeriodEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserSettingsDto
     */
    showSymbolAmountWarning?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserSettingsDto
     */
    showPortfolioValueCalculationInfo?: boolean;
}

export function UpdateUserSettingsDtoFromJSON(json: any): UpdateUserSettingsDto {
    return UpdateUserSettingsDtoFromJSONTyped(json, false);
}

export function UpdateUserSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserSettingsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'theme': !exists(json, 'theme') ? undefined : ThemeEnumFromJSON(json['theme']),
        'layout': !exists(json, 'layout') ? undefined : LayoutEnumFromJSON(json['layout']),
        'mailOverviewPeriod': !exists(json, 'mailOverviewPeriod') ? undefined : MailOverviewPeriodEnumFromJSON(json['mailOverviewPeriod']),
        'showSymbolAmountWarning': !exists(json, 'showSymbolAmountWarning') ? undefined : json['showSymbolAmountWarning'],
        'showPortfolioValueCalculationInfo': !exists(json, 'showPortfolioValueCalculationInfo') ? undefined : json['showPortfolioValueCalculationInfo'],
    };
}

export function UpdateUserSettingsDtoToJSON(value?: UpdateUserSettingsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'theme': ThemeEnumToJSON(value.theme),
        'layout': LayoutEnumToJSON(value.layout),
        'mailOverviewPeriod': MailOverviewPeriodEnumToJSON(value.mailOverviewPeriod),
        'showSymbolAmountWarning': value.showSymbolAmountWarning,
        'showPortfolioValueCalculationInfo': value.showPortfolioValueCalculationInfo,
    };
}

