import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
import type { AffiliateStore } from './affiliate-store';
import { AffiliateStatisticMobxDto } from '../mobx/dtos/affiliate/affiliate-statistic-mobx-dto';

export class AffiliateStatisticStore {
    rootStore: RootStore;

    affiliateStore: AffiliateStore;

    loading = false;

    affiliateStatistic: AffiliateStatisticMobxDto | null = null;

    constructor(affiliateStore: AffiliateStore) {
        this.rootStore = affiliateStore.rootStore;
        this.affiliateStore = affiliateStore;

        makeAutoObservable(this, {
            rootStore: false,
            affiliateStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async fetchAffiliateStatistic(affiliateId: string, dateFrom?: Date, dateTo?: Date): Promise<void> {
        this.startLoading();
        try {
            const [affiliateStatisticDto] = await Promise.all([
                this.rootStore.apiClient.affiliateController.affiliateControllerGetStatistics({
                    affiliateId,
                    dateFrom: dateFrom?.toISOString(),
                    dateTo: dateTo?.toISOString(),
                }),
            ]);

            runInAction(() => {
                this.affiliateStatistic = AffiliateStatisticMobxDto.create(affiliateStatisticDto);
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            this.rootStore.alertStore.setErrorMessageByStatus(e.message);
        } finally {
            this.stopLoading();
        }
    }
}
