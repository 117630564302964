import { makeAutoObservable } from 'mobx';
import { AffiliateStatisticDto } from '../../../../defs/api';

export class AffiliateStatisticMobxDto implements AffiliateStatisticDto {
    commissions: number = 0;

    paymentsCount: number = 0;

    registrationsCount: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: AffiliateStatisticDto): AffiliateStatisticMobxDto {
        const mobxDto = new AffiliateStatisticMobxDto();
        return Object.assign(mobxDto, dto, {});
    }

    static createFromArray(dtos: AffiliateStatisticDto[]): AffiliateStatisticMobxDto[] {
        return dtos.map((dto) => AffiliateStatisticMobxDto.create(dto));
    }
}
