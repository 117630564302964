import { makeAutoObservable } from 'mobx';
import { LayoutEnum, MailOverviewPeriodEnum, ThemeEnum, UserSettingsDto } from '../../../../defs/api';

export class UserSettingsMobxDto implements UserSettingsDto {
    id: string = '';

    userId: string = '';

    theme: ThemeEnum = ThemeEnum.Light;

    layout: LayoutEnum = LayoutEnum.Vertical;

    mailOverviewPeriod: MailOverviewPeriodEnum = MailOverviewPeriodEnum.Weekly;

    showSymbolAmountWarning: boolean = true;

    showPortfolioValueCalculationInfo: boolean = true;

    constructor() {
        makeAutoObservable(this, {});
    }

    static create(dto: UserSettingsDto): UserSettingsMobxDto {
        const mobxDto = new UserSettingsMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: UserSettingsDto[]): UserSettingsMobxDto[] {
        return dtos.map((dto) => UserSettingsMobxDto.create(dto));
    }
}
