import { makeAutoObservable } from 'mobx';
import { AffiliateOverviewDto } from '../../../../defs/api';
import { AffiliateSubscriptionTypeDataMobxDto } from './affiliate-subscription-type-data-mobx-dto';

export class AffiliateOverviewMobxDto implements AffiliateOverviewDto {
    code: string = '';

    id: string = '';

    lastUpdated: Date = new Date();

    rate: number = 0;

    userId: string = '';

    currentBalance: number = 0;

    totalPaidOut: number = 0;

    subscriptionTypeData: AffiliateSubscriptionTypeDataMobxDto;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: AffiliateOverviewDto): AffiliateOverviewMobxDto {
        const mobxDto = new AffiliateOverviewMobxDto();
        return Object.assign(mobxDto, dto, {});
    }

    static createFromArray(dtos: AffiliateOverviewDto[]): AffiliateOverviewMobxDto[] {
        return dtos.map((dto) => AffiliateOverviewMobxDto.create(dto));
    }
}
