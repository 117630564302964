import { makeAutoObservable } from 'mobx';
import { AffiliatePayoutDto, AffiliatePayoutStatusEnum } from '../../../../defs/api';

export class AffiliatePayoutMobxDto implements AffiliatePayoutDto {
    affiliateId: string = '';

    amount: number = 0;

    createdAt: Date = new Date();

    id: string = '';

    fileId: string;

    status: AffiliatePayoutStatusEnum = AffiliatePayoutStatusEnum.InProgress;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: AffiliatePayoutDto): AffiliatePayoutMobxDto {
        const mobxDto = new AffiliatePayoutMobxDto();
        return Object.assign(mobxDto, dto, {});
    }

    static createFromArray(dtos: AffiliatePayoutDto[]): AffiliatePayoutMobxDto[] {
        return dtos.map((dto) => AffiliatePayoutMobxDto.create(dto));
    }
}
