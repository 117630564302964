/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AffiliatePayoutStatusEnum,
    AffiliatePayoutStatusEnumFromJSON,
    AffiliatePayoutStatusEnumFromJSONTyped,
    AffiliatePayoutStatusEnumToJSON,
} from './AffiliatePayoutStatusEnum';

/**
 * 
 * @export
 * @interface AffiliatePayoutDto
 */
export interface AffiliatePayoutDto {
    /**
     * 
     * @type {AffiliatePayoutStatusEnum}
     * @memberof AffiliatePayoutDto
     */
    status: AffiliatePayoutStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AffiliatePayoutDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof AffiliatePayoutDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof AffiliatePayoutDto
     */
    affiliateId: string;
    /**
     * 
     * @type {string}
     * @memberof AffiliatePayoutDto
     */
    fileId: string;
    /**
     * 
     * @type {Date}
     * @memberof AffiliatePayoutDto
     */
    createdAt: Date;
}

export function AffiliatePayoutDtoFromJSON(json: any): AffiliatePayoutDto {
    return AffiliatePayoutDtoFromJSONTyped(json, false);
}

export function AffiliatePayoutDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AffiliatePayoutDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': AffiliatePayoutStatusEnumFromJSON(json['status']),
        'id': json['id'],
        'amount': json['amount'],
        'affiliateId': json['affiliateId'],
        'fileId': json['fileId'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function AffiliatePayoutDtoToJSON(value?: AffiliatePayoutDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': AffiliatePayoutStatusEnumToJSON(value.status),
        'id': value.id,
        'amount': value.amount,
        'affiliateId': value.affiliateId,
        'fileId': value.fileId,
        'createdAt': (value.createdAt.toISOString()),
    };
}

